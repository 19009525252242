.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.glow {
  /* font-family: "Poppins", sans-serif; */
  /* text-shadow: 0 0 82px #97338f, 0 0 151px #97338f; */
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

.img_glow {
  box-shadow: 0px 0px 500px #fff;
}

.text_glow {
  text-shadow: 0px 0px 50px #fff;
}

.mint_glow {
  box-shadow: 0px 0px 50px #97338f;
}

.wallet-adapter-button.connect_button {
  background: #97338f;
  color: white;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #97338f,
      0 0 40px #97338f, 0 0 50px #97338f, 0 0 60px #97338f, 0 0 70px #97338f;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #97338f, 0 0 40px #97338f,
      0 0 50px #97338f, 0 0 60px #97338f, 0 0 70px #97338f, 0 0 80px #97338f;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
